@keyframes shake {
  0% {
    transform: rotateZ(0);
  }
  10% {
    transform: rotateZ(-30deg);
  }
  20% {
    transform: rotateZ(15deg);
  }
  30% {
    transform: rotateZ(-10deg);
  }
  40% {
    transform: rotateZ(7.5deg);
  }
  50% {
    transform: rotateZ(-6deg);
  }
  60% {
    transform: rotateZ(5deg);
  }
  70% {
    transform: rotateZ(-4.28571deg);
  }
  80% {
    transform: rotateZ(3.75deg);
  }
  90% {
    transform: rotateZ(-3.33333deg);
  }
  100% {
    transform: rotateZ(0);
  }
}

.wrap {
  display: inline-block;
  /* float: right; */
  /* padding: 5px; */
}

span[class*='icon'] {
  display: inline-block;
}

.container {
  position: relative;
  perspective: 100px;
  transform-style: preserve-3d;
}

#phonewrap {
  /* position: absolute;
  right: 10px; */
  float: right;
  margin-right: 1rem;
  /* position: absolute; */
  /* right: 8px; */
}

.icon {
  /* margin-left: 2rem; */
  /* float: right; */
}

#phonewrap:hover {
  display: inline-block;
  cursor: pointer;
  animation: shake 1200ms;
}

#jsoncode:hover {
  cursor: pointer;
  animation: shake 1200ms;
}

.userCard #phonewrap {
  float: right;
  margin-right: -90%;
}
