#toast {
  display: none;
  position: absolute;
  top: 100px;
  left: 85%;
  padding: 20px;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.05);
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: white;
}

#copy-btn svg {
  /* float: right !important; */
  /* margin: 0.1rem; */
}

#toast.show-toast {
  display: inline-block;
}

.show-toast {
  animation: showToast 0.3s ease-out;
}

.hide-toast {
  animation: hideToast 0.3s ease-in;
}

@keyframes showToast {
  from {
    top: 170px;
    opacity: 0;
  }
  to {
    top: 100px;
    opacity: 1;
  }
}

@keyframes hideToast {
  from {
    top: 100px;
    opacity: 1;
  }
  to {
    top: 20px;
    opacity: 0;
  }
}
