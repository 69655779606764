/* ----------------------------------------------------------------------------------------------- */
/* -------------------------  styles for connector paths when editing ---------------------------- */
/* ----------------------------------------------------------------------------------------------- */

/* jtk-connection-edit is set on an edge that is being edited. */
.jtk-connection-edit path {
    stroke:gold;
}

/* ----------------------------------------------------------------------------------------------- */
/* --------------------    Styles for Flowchart editor ------------------------------------------- */
/* ----------------------------------------------------------------------------------------------- */

.jtk-flowchart-handle {
    width:20px;
    height:20px;
    line-height: 20px;
    text-align: center;
    font-size:13px;
    border-radius:50%;
    background-color:#f7f6f6;
    cursor:move;
    z-index:10;
    color:black;
    box-shadow: 0 0 10px;
}

.jtk-flowchart-segment-drag {
    cursor:move;
    cursor:grab;
}

.jtk-flowchart-segment-drag-ns:before {
    content:"\2195";
}

.jtk-flowchart-segment-drag-ew:before {
    content:"\2194";
}

.jtk-flowchart-segment-drag:hover {
    background-color: white;
}

/* ----------------------------------------------------------------------------------------------- */
/* --------------------    Styles for Bezier/StateMachine editor --------------------------------- */
/* ----------------------------------------------------------------------------------------------- */

/*
    Identifies a handle used to drag a control point.
*/
.jtk-bezier-handle {
    width:15px;
    height:15px;
    line-height:15px;
    border-radius:50%;
    background-color:darkcyan;
    cursor:move;
    z-index:10;
    font-size:8px;
    font-family: sans-serif;
    color: white;
    text-align: center;
}

.jtk-bezier-handle-control-point { }

/*
    The drag handle corresponding to the first control point. For StateMachine connectors only this handle is shown.
 */
.jtk-bezier-handle-control-point-1:before {
    content:"1";
}

/*
    The drag handle corresponding to the second control point. For StateMachine connectors only this handle is shown.
 */
.jtk-bezier-handle-control-point-2:before {
    content:"2";
}

.jtk-bezier-handle-control-point-3:before {
    content:"3";
}

.jtk-bezier-handle-control-point-4:before {
    content:"4";
}

.jtk-bezier-handle-secondary {
    cursor:default;
    background-color:lightgreen;
}

.jtk-bezier-handle-secondary-source {
    background-color: lavenderblush;
}

.jtk-bezier-guideline {
    position:absolute;
    stroke-dasharray: 2;
}

.jtk-bezier-guideline path {
    stroke:darkcyan;
    stroke-linewidth:3;
}

/* ----------------------------------------------------------------------------------------------- */
/* -----------------------------   Styles for anchor placeholders -------------------------------- */
/* ----------------------------------------------------------------------------------------------- */

/*
    Added to the element placed at the location of an anchor for a connection that is being edited. This applies
    to both dynamic and continuous anchors. Fixed anchors cannot be relocated.
*/
.jtk-anchor-placeholder {
    width: 20px;
    height: 20px;
    cursor: move;
    cursor:grab;
    border-radius: 50%;
    background-color:#f7f6f6;
    box-shadow: 0 0 4px;
}

/*
    Added to the elements drawn to show where a certain anchor may be relocated to (for dynamic anchors)
*/
.jtk-anchor-candidate {
    background-color:mediumseagreen;
    width: 20px;
    height: 20px;
}

/*
    `jtk-anchor-face` is an attribute written onto an element (source or target) when the connection that is being edited
    has `Continuous` anchors, and the user is dragging an anchor point to relocate it. The value of the attribute is the
    candidate face on the element.
 */
[jtk-anchor-face='left'] {
    border-left:4px solid gold !important;
}

[jtk-anchor-face='top'] {
    border-top:4px solid gold !important;
}

[jtk-anchor-face='right'] {
    border-right:4px solid gold !important;
}

[jtk-anchor-face='bottom'] {
    border-bottom:4px solid gold !important;
}


/* --------------------------------------------------------------------------------------------------------------------- */
/* --------------------------- edge delete button ---------------------------------------------------------------------- */
/* --------------------------------------------------------------------------------------------------------------------- */

.jtk-edge-delete {
    width:15px;
    height:15px;
    line-height: 15px;
    text-align: center;
    background-color:orangered;
    color:white;
    border-radius:50%;
    font-size:8px;
    cursor:pointer;
    font-family:sans-serif;
}

.jtk-edge-delete:before {
    content:"X";
}
