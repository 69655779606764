@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=K2D:wght@400;700&display=swap');
/* // Lab page */

.swal-modal {
  background-color: #171717;
  color: #777 !important;
}

.swal-icon--success__hide-corners {
  /* background: none; */
  display: none;
  /* opacity: 0; */
}

.swal-icon--success:after,
.swal-icon--success:before {
  position: none;
  width: 0;
  height: 0;
}

.swal-icon--success:after,
.swal-icon--success:before {
  background: #171717;
}

.swal-title {
  color: #f18f3c !important;
}
.swal-text {
  color: #777 !important;
}
.swal-button {
  background-color: #fff;
  color: #777;
}
.swal-button:not([disabled]):hover {
  background-color: #fff;
  color: #777;
}

.labIcon {
  cursor: 'pointer' !important;
}

.btnLogin {
  font-weight: 500;
  font-size: 1em;
  text-transform: uppercase;
  padding: 18px 50px;
  background: transparent;
  border: none !important;
  transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
  position: relative;
  z-index: 2;
}

.passwordField {
}
.authFormWrap .form-group .form-control {
  color: #f18f3c !important;
}

.btnLogin:after {
  content: '';
  position: absolute;
  transition: 0.4s all cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.btn-4 {
  color: #0e0e16;
  border: 2px solid #0e0e16;
}
.btn-4:after {
  border: solid #0e0e16;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(-45deg) translateY(-48%);
  top: 48%;
  right: 25%;
  opacity: 0;
  visibility: hidden;
  z-index: -1;
}
.btn-4:hover {
  border: 2px solid transparent;
  color: black;
}
.btn-4:hover:after {
  opacity: 1;
  visibility: visible;
  right: 15%;
}
.loginBtnText {
  color: rgb(84, 76, 76);
}
.loginBtnText:hover {
  transition: all 0.5s;
  color: black;
}

.companyLogo {
  width: 12rem !important;
}

.card-title {
  /* text-align: center; */
  /* font-size: 3rem !important; */
}
.percent {
  font-size: 3rem !important;
}

.btn-group-toggle {
  padding-top: 10px;
  height: 40px;
}
.group-by-wrap {
  padding-top: -51px !important;
}

.card-title {
  display: flex;
  justify-content: space-between;
  flex: none;
  padding: 1rem;
}

.loginForm {
  font-family: 'Poppins', sans-serif;
}

.sidebarMenuInner li {
  overflow: hidden !important;
}

.designLabSidebarWrapper .designLabSidebar {
  margin-left: 0 !important;
}

.zoom-slider-wrap input {
  margin-left: 32px;
}

.drawing-board-container {
  height: 75vh !important;
}

.jtk-overlay * {
  width: 1rem !important;
  max-width: 1rem !important;
}

.sidebar-wrapper .sidebarMenuInner li {
  padding-right: 0 !important;
}

.jtk-overlay {
  border-radius: 70% !important;
  padding: 1rem !important;
  font-size: x-small !important;
}

.chatOverlay {
  cursor: pointer;
}

.terminalContainer {
  opacity: 0.5;
}

.userCardReset {
  padding: 0 !important;
  margin: 0 !important;
}

/* .drawing-board-sketchpad-canvas {
  height: 300px !important;
}

.drawing-board-sketchpad-container {
  height: 300px !important;
}
.ant-layout-content {
  max-height: 300px !important ;
} */

/* .pathEmu_dropdown__image {
  background-image: url('https://em-content.zobj.net/thumbs/160/apple/354/wireless_1f6dc.png');
  display: inline-block;
  width: 24px;
  height: 24px;
  margin-right: 5px;
} */

.teams-card {
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
  background: none !important;
  border: 1px solid #222322;
  /* transition: all 0.3s; */
}

.teams-card:hover {
  transform: scale(1.05);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}
