// Here you can add other styles

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

$primary: #eb8a0d;
$white: #fff;
$black: #000;
$lightBlack: #333;
$danger: #f86c6b;

$primary: #eb8a0d;
$white: #fff;
$black: #000;
$lightBlack: #333;
$danger: #f86c6b;

body {
  font-family: 'Poppins', sans-serif;
}

.btn {
  outline: 0 !important;
  box-shadow: none !important;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  letter-spacing: 0.5px;
  transform: unset !important;
}

a {
  color: #f18f3c !important;
  font-weight: 400 !important;
}

label {
  font-size: 14px !important;
  letter-spacing: 0.3px;
  padding-left: 2px;
}

.ainput-group,
.form-group {
  margin-bottom: 15px !important;
  color: gray;
}

.form-control {
  font-size: 15px !important;
  font-weight: 400;
  letter-spacing: 0.3px;
  padding: 6px 12px !important;
  border: 1px solid #ced4da !important;

  &:focus {
    border-color: #f18f3c;
  }
}

.themeBtn {
  background-color: #f18f3c;
  border: 1px solid #f18f3c !important;
  background-image: none;
  color: #fff !important;
  font-size: 16px;
  margin: 20px auto 10px;
  width: 280px;
  display: block;
  height: 42px;
  font-weight: 600 !important;
  line-height: 42px;
  padding: 0 12px;

  &:hover {
    background-color: #f18f3c !important;
    background-image: none !important;
  }
}

.themeBtn:focus,
.themeBtn:active,
.themeBtn.active,
.themeBtn:active:focus,
.themeBtn:active:hover,
.themeBtn.active:focus,
.themeBtn.active:hover {
  background-color: #f18f3c !important;
  background-image: none !important;
}

.validation-error {
  color: #f16667;
  font-size: 13px;
  margin-top: 3px;
  padding-left: 5px;
}

.fs-16 {
  font-size: 16px !important;
}

.table-responsive {
  overflow: unset !important;
  overflow-x: auto !important;
  padding-bottom: 0 !important;
}

.table > thead > tr > th {
  font-size: 14px;
}

.footer {
  display: flex;
  // justify-content: space-between;
  justify-content: center;
  // align-items: center;
  // padding: 10px 15px 15px 15px !important;

  .copyright {
    float: unset !important;
    padding: 0 10px !important;
  }

  .nav {
    float: unset !important;
    align-self: flex-end;
    padding-left: 10px !important;
  }

  &:after {
    display: none !important;
  }

  a {
    color: #697ba0 !important;
  }

  .lsWebsite {
    color: #31c5c7 !important;
  }
}

.main-panel > .content {
  // padding: 78px 20px 15px 270px !important;
  // padding: 78px 20px 15px 120px !important;
  // min-height: calc(100vh - 49px) !important;
}

.dropdown-menu .dropdown-item {
  outline: 0 !important;
  color: #9a9a9a !important;
}

.sideMenuLogo {
  width: 165px;
}

.main-panel[data='blue'] {
  border-top: 2px solid #f18f3c;
}

.sidebar[data='blue'] {
  background: linear-gradient(0deg, #fbd449 0%, #f4a03f 100%);
}

.sidebar .nav p {
  font-size: 14px;
  font-weight: 500;
}

.sidebar .nav i {
  margin-right: 8px;
}

.modal-header {
  border-bottom: 1px solid #e9ecef !important;
  padding: 18px 24px 14px !important;
}

.modal-title {
  color: white !important;
  font-size: 18px;
  font-weight: 500;
}

.modal .modal-header .close {
  color: #fd5d93 !important;
  padding: 10px 10px 5px !important;
}

.modal-body {
  padding-top: 16px !important;

  .form-control {
    color: #fff !important;
  }
}

.modal-footer {
  padding: 14px 24px 14px !important;
  justify-content: flex-end !important;

  .btn {
    margin-left: 12px !important;
    height: 36px;
    padding: 0 12px !important;
    line-height: 34px !important;
    border: 1px solid #f18f3c !important;
    border-radius: 6px !important;
  }

  .modalBtnCancel {
    background-color: transparent !important;
    border: 1px solid #a5a5a5 !important;
    background-image: none !important;
    color: #a5a5a5 !important;

    &:hover {
      background-color: transparent !important;
      background-image: none !important;
      color: #a5a5a5 !important;
    }
  }

  .modalBtnCancel:focus,
  .modalBtnCancel:active,
  .modalBtnCancel.active,
  .modalBtnCancel:active:focus,
  .modalBtnCancel:active:hover,
  .modalBtnCancel.active:focus,
  .modalBtnCancel.active:hover {
    background-color: transparent !important;
    background-image: none !important;
    color: #a5a5a5 !important;
  }

  .modalBtnSave {
    background-color: #f18f3c !important;
    border: 1px solid #f18f3c !important;
    background-image: none !important;
    color: #fff !important;

    &:hover {
      background-color: #f18f3c !important;
      background-image: none !important;
      color: #fff !important;
    }
  }

  .modalBtnSave:focus,
  .modalBtnSave:active,
  .modalBtnSave.active,
  .modalBtnSave:active:focus,
  .modalBtnSave:active:hover,
  .modalBtnSave.active:focus,
  .modalBtnSave.active:hover {
    background-color: #f18f3c !important;
    background-image: none !important;
    color: #fff !important;
  }
}

.login-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
}

.companyLogo {
  width: 310px;
  display: block;
  margin: 8px auto 35px;
}

.authFormWrap {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  border: 2px solid #665f5f;
  border-radius: 1rem;
  // box-shadow: 0 0.46875rem 2.1875rem rgba(0, 0, 0, 0.03), 0 0.9375rem 1.40625rem rgba(0, 0, 0, 0.03),
  //   0 0.25rem 0.53125rem rgba(0, 0, 0, 0.05), 0 0.125rem 0.1875rem rgba(0, 0, 0, 0.03);

  .card {
    margin-bottom: 0;
    border-radius: 12px;
    padding-top: 1.5rem;

    label,
    .forgotPassword {
      color: #676767 !important;
    }

    .card-header {
      padding: 22px 25px 0;

      .card-title {
        font-weight: 600;
        color: #f18f3c;
        font-size: 26px;
      }
    }

    .card-body {
      padding: 12px 25px 15px;
    }
  }

  .form-group {
    position: relative;

    .form-control {
      padding-left: 34px !important;
      color: #fff !important;
      // color: #333 !important;

      &:focus {
        border-color: #f18f3c !important;
      }
    }

    .formIcon {
      position: absolute;
      top: 12px;
      left: 12px;
      font-size: 14px;
      color: #b0b0b0;
    }

    .lockIcon {
      font-size: 15px;
      top: 11.5px;
    }

    .callIcon {
      top: 12.5px;
    }

    .mailIcon {
      top: 11.5px;
    }

    .showPassword {
      position: absolute;
      top: 12px;
      right: 12px;
      color: #a0a0a0;
    }

    .passwordField {
      padding-right: 35px !important;
    }
  }

  .themeBtn {
    margin: 30px auto !important;
  }

  .createAccount {
    color: #808080 !important;
    display: block;
    margin: 0 auto 10px;
    width: 250px;
    text-align: center;
    letter-spacing: 0.3px;

    span {
      color: #f18f3c !important;
      font-weight: 500;
    }
  }
}

.authFooter {
  width: 100%;
  max-width: 500px;
  margin: 12px auto;
  display: flex;
  justify-content: space-between;
  color: #bbb;
  font-size: 14px;

  a {
    color: #f6bb44 !important;
  }
}

.tableFilterWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px !important;

  .form-control {
    border-color: #676767 !important;
    color: #ddd !important;

    &:focus {
      border-color: #f18f3c !important;
    }
  }
}

.addBtn {
  background-color: #f18f3c !important;
  border-color: #f18f3c !important;
  color: #fff !important;
  height: 38px;
  line-height: 38px !important;
  background-image: none !important;
  padding: 0 15px !important;

  &:hover,
  &:focus,
  &:active,
  &:active:focus,
  &:active:hover {
    background-color: #f18f3c !important;
    background-image: none !important;
    color: #fff !important;
  }
}

.addBtn.active {
  background-color: #f18f3c !important;
  background-image: none !important;
  color: #fff !important;

  &:focus,
  &:hover {
    background-color: #f18f3c !important;
    background-image: none !important;
    color: #fff !important;
  }
}

.viewBtn {
  background-color: transparent !important;
  background-image: none !important;
  border: 1px solid #887f77 !important;
  color: #fff !important;
  height: 32px;
  line-height: 30px !important;
  padding: 0 12px !important;

  &:hover {
    background-color: #737170 !important;
    background-image: none !important;
    color: #fff !important;
  }
}

.viewBtn:focus,
.viewBtn:active,
.viewBtn.active,
.viewBtn:active:focus,
.viewBtn:active:hover,
.viewBtn.active:focus,
.viewBtn.active:hover {
  background-color: #f18f3c !important;
  background-image: none !important;
  color: #fff !important;
}

.stopBtn {
  background: transparent !important;
  border: 1px solid #f16667 !important;
  height: 32px;
  line-height: 30px !important;
  padding: 0 12px !important;

  &:hover {
    background-color: #f16667 !important;
    background-image: none !important;
    color: #fff !important;
  }
}

.stopBtn:focus,
.stopBtn:active,
.stopBtn.active,
.stopBtn:active:focus,
.stopBtn:active:hover,
.stopBtn.active:focus,
.stopBtn.active:hover {
  background-color: #f16667 !important;
  background-image: none !important;
  color: #fff !important;
}

.licensesList li {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #666;
  border-radius: 0 !important;
  display: flex;
  justify-content: space-between;
  padding: 18px 5px;

  &:first-child {
    padding-top: 15px;
  }

  &:last-child {
    border-bottom: 0;
    padding-bottom: 15px;
  }
}

.userInfo {
  .card-header {
    padding-bottom: 10px !important;
    border-bottom: 1px solid #525252 !important;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.3px;
  }

  .card-footer {
    border-top: 1px solid #525252 !important;
    display: flex;
    justify-content: space-between;
    padding: 20px 15px 22px !important;

    .btn {
      margin: 0 0 0 20px;
      flex-shrink: 0;
    }
  }

  label {
    color: #f5f5f5 !important;
  }
}

.card-chart .card-header {
  .card-category {
    font-size: 15px;
    letter-spacing: 0.3px;
    margin-bottom: 8px !important;
  }

  .card-title i {
    font-size: 22px !important;
    color: #f18f3c;
  }
}

.dashboardCard {
  text-align: center;
  cursor: pointer;
  // padding: 65px 0;

  i {
    font-size: 36px;
    color: #f18f3c;
  }

  h3 {
    font-size: 28px;
    margin: 15px 0 10px;
    font-weight: 600;
  }

  p {
    margin-bottom: 5px;
    font-size: 15px;
    letter-spacing: 0.3px;
    font-weight: 500;
  }
}

.custom-control-label::before,
.custom-control-label::after {
  border: #adb5bd solid 1px;
  top: -8px !important;
  width: 1rem !important;
  height: 1rem !important;
}

.custom-control-label::after {
  border: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before,
.custom-control-input:active ~ .custom-control-label::before {
  background-color: #f18f3c;
  border-color: #f18f3c;
}

.saveBtn {
  background-color: #f18f3c !important;
  border: 1px solid #f18f3c !important;
  background-image: none !important;
  color: #fff !important;
  font-size: 16px !important;

  &:hover {
    background-color: #f18f3c !important;
    background-image: none !important;
    color: #fff !important;
  }
}

.saveBtn:focus,
.saveBtn:active,
.saveBtn.active,
.saveBtn:active:focus,
.saveBtn:active:hover,
.saveBtn.active:focus,
.saveBtn.active:hover {
  background-color: #f18f3c !important;
  background-image: none !important;
  color: #fff !important;
}

.cancelBtn {
  background-color: transparent !important;
  border: 1px solid #a5a5a5 !important;
  background-image: none !important;
  color: #a5a5a5 !important;
  font-size: 16px !important;

  &:hover {
    background-color: transparent !important;
    background-image: none !important;
    color: #a5a5a5 !important;
  }
}

.cancelBtn:focus,
.cancelBtn:active,
.cancelBtn.active,
.cancelBtn:active:focus,
.cancelBtn:active:hover,
.cancelBtn.active:focus,
.cancelBtn.active:hover {
  background-color: transparent !important;
  background-image: none !important;
  color: #a5a5a5 !important;
}

.assetImg {
  height: 50px;
  width: 50px;
  object-fit: cover;
  border: 1px solid #909090;
  padding: 1px;
  border-radius: 6px;
  margin-right: 8px;
}

.newLabBtn {
  position: relative;
  // bottom: 30px;
  // right: 20px;
  left: 92%;
  padding: 0 14px;
  height: 40px;
  line-height: 40px;
  background-color: #f18f3c;
  border: 1px solid #f18f3c !important;
  background-image: none;
  font-size: 15px;

  &:hover {
    background-color: #f18f3c !important;
    background-image: none !important;
  }
}

.newLabBtn:focus,
.newLabBtn:active,
.newLabBtn.active,
.newLabBtn:active:focus,
.newLabBtn:active:hover,
.newLabBtn.active:focus,
.newLabBtn.active:hover {
  background-color: #f18f3c !important;
  background-image: none !important;
}

.tableInModal {
  margin-bottom: 0;
}

.tableInModal > thead > tr > th {
  color: #c0b2b2 !important;
  border-bottom: 2px solid #dee2e6;
  font-size: 14px;
  letter-spacing: 0.3px;
}

.tableInModal > tbody > tr > td {
  color: #676767 !important;
  border-top: 1px solid #dee2e6;
}

.tableInModal {
  .viewBtn {
    color: #f18f3c !important;

    &:hover {
      color: #fff !important;
    }
  }

  .viewBtn:focus,
  .viewBtn:active,
  .viewBtn.active,
  .viewBtn:active:focus,
  .viewBtn:active:hover,
  .viewBtn.active:focus,
  .viewBtn.active:hover {
    color: #fff !important;
  }

  .stopBtn {
    color: #f16667 !important;

    &:hover {
      color: #fff !important;
    }
  }

  .stopBtn:focus,
  .stopBtn:active,
  .stopBtn.active,
  .stopBtn:active:focus,
  .stopBtn:active:hover,
  .stopBtn.active:focus,
  .stopBtn.active:hover {
    color: #fff !important;
  }
}

.isAdminCheck .custom-control-label::before,
.isAdminCheck .custom-control-label::after {
  top: 5px !important;
}

.isAdminWrap {
  label {
    margin-bottom: 0;
  }

  .isAdminCheck .custom-control-label::before,
  .isAdminCheck .custom-control-label::after {
    top: 2px !important;
  }
}
// .switch-toggle-button{
// 	font-size: 10px;
// 	.react-switch-handle{

// 		// transform: translateX(20px);
// 		// width:40px
// 	}
// 	.react-switch-bg{
// 		// padding:0 5px;
// // width:40px !important;
// 	}
// }

// Design Lab Side Menu CSS
.designLabSidebar .nav {
  margin-top: 50px;
}

#sidebarMenu {
  position: absolute;
  left: 0;
  transform: translateX(-180px);
  transition: transform 250ms ease-in-out;
  z-index: 98;
  overflow: auto;
  scrollbar-color: rgba(103, 112, 210, 0.8) #bbb;
  scrollbar-width: thin;
}

#sidebarMenu::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background-color: #bbb;
}

#sidebarMenu::-webkit-scrollbar {
  width: 8px;
  background-color: #bbb;
}

#sidebarMenu::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.8);
  background-color: rgba(103, 112, 210, 0.8);
}

.sidebarMenuInner li {
  list-style: none;
  color: #fff;
  padding: 11px 16px;
  font-size: 14px;
  cursor: pointer;
  position: relative;
  overflow: auto;
}

.designLabSidebar .sidebarMenuInner li i {
  margin-right: 3px;
}

.designLabSidebar .sidebarMenuInner li img {
  margin-right: 5px;
}

#sidebarMenu .sidebarMenuInner li p {
  display: none;
}

input[type='checkbox']:checked ~ #sidebarMenu {
  transform: translateX(0px);
  transition: transform 250ms ease-in-out;
}

input[type='checkbox']:checked ~ #sidebarMenu .sidebarMenuInner li {
  padding-right: 21px;
}

input[type='checkbox']:checked ~ #sidebarMenu .sidebarMenuInner li div {
  justify-content: flex-start !important;
}

input[type='checkbox']:checked ~ #sidebarMenu .sidebarMenuInner li p {
  display: initial;
}

input[type='checkbox']:checked ~ #sidebarMenu .sidebarMenuInner li i {
  font-size: 23px;
  margin-right: 10px;
}

input[type='checkbox']:checked ~ #sidebarMenu .sidebarMenuInner li img {
  margin-right: 13px;
}

.designLabSidebarWrapper input[type='checkbox'] {
  transition: all 0.3s;
  box-sizing: border-box;
  display: none;
}

.sidebarIconToggle {
  transition: all 0.3s;
  box-sizing: border-box;
  cursor: pointer;
  position: absolute;
  z-index: 100;
  height: 100%;
  width: 100%;
  top: 100px;
  left: 23px;
  height: 22px;
  width: 22px;
  margin-bottom: 0;
}

input[type='checkbox']:checked + .sidebarIconToggle {
  // left: 213px;
  // margin-left: 10px;
  top: 95px;
}

.spinner {
  transition: all 0.3s;
  box-sizing: border-box;
  position: absolute;
  height: 3px;
  width: 100%;
  background-color: #fff;
}

.horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  opacity: 1;
  margin-top: 3px;
}

.diagonal.part-1 {
  position: relative;
  transition: all 0.3s;
  box-sizing: border-box;
  float: left;
  transform: rotate(0deg);
  margin-top: 1px;
}

.diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  position: relative;
  float: left;
  transform: rotate(0deg);
  margin-top: 3px;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .horizontal {
  transition: all 0.3s;
  box-sizing: border-box;
  opacity: 0;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-1 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(135deg);
  margin-top: 9px;
}

input[type='checkbox']:checked ~ .sidebarIconToggle > .diagonal.part-2 {
  transition: all 0.3s;
  box-sizing: border-box;
  transform: rotate(-135deg);
  margin-top: -9px;
}

.sideMenuTooltip .tooltip.show {
  left: 125px !important;
  font-size: 14px;
}

.main-panel > .designLabSideMenuClosedPadding {
  // Change to 0
  padding-left: 0px !important;
  padding-right: 0 !important;
}

@media all and (min-width: 768px) and (max-width: 991.98px) {
  .main-panel > .content {
    padding-left: 15px !important;
  }

  // 	.navbar-expand-lg .navbar-right {
  //     display: none;
  //   }
  //   .navbar-expand-lg .navbar-collapse {
  //     display: flex !important;
  //     flex-basis: auto;
  //   }
  //   .navbar-expand-lg .navbar-nav .nav-link {
  //     padding-right: 0.5rem;
  //     padding-left: 0.5rem;
  //   }
  //   .navbar .navbar-nav .dropdown {
  //     margin: 0;
  //   }
  //   .navbar .caret {
  //     position: absolute;
  //     left: 80%;
  //     top: 55%;
  //     margin-left: 0;
  //   }
  //   .dropdown-toggle:after {
  //     display: none;
  //   }
  //   .navbar .navbar-nav .dropdown .nav-link {
  //     padding-bottom: 0.5rem;
  //   }
  //   .navbar-expand-lg .navbar-nav .dropdown-menu {
  //     position: absolute !important;
  //   }
  //   .navbar .dropdown.show .dropdown-menu, .navbar .dropdown .dropdown-menu {
  //     background-color: #fff;
  //     box-shadow: 0px 10px 50px 0px rgba(0, 0, 0, 0.2);
  //     transition: all 150ms linear;
  //     margin: 0.125rem 0 0;
  //   }
  //   .navbar-nav li {
  //     padding: 0 10px !important;
  //   }
  //   .navbar .navbar-nav .dropdown .dropdown-menu .dropdown-item {
  //     margin-top: 5px;
  //   }
  //   .dropdown-menu:before {
  //     display: inline-block !important;
  //  }
}

// @media (min-width: 992px) {
//   .main-panel {
//     width: 100%;
//   }
//   .nav-open .main-panel {
//     right: 0;
//     transform: translate3d(260px, 0, 0);
//   }
//   .main-panel > .content {
//     padding-left: 15px !important;
//   }
//   .navbar-expand-lg .navbar-toggle .navbar-toggler {
//     display: inline;
//   }
//   .navbar .navbar-toggler-bar {
//     display: block;
//     position: relative;
//     width: 22px;
//     height: 1px;
//     border-radius: 1px;
//     background: #FFFFFF;
//   }
//   .navbar .navbar-brand {
//     margin-left: 20px;
//     position: relative;
//   }
//   .bar1, .bar2, .bar3 {
//     outline: 1px solid transparent;
//   }
//   .bar1 {
//     top: 0px;
//     animation: topbar-back 500ms 0s;
//     animation-fill-mode: forwards;
//   }
//   .navbar .navbar-toggler-bar.bar2 {
//     width: 17px;
//     transition: width .2s linear;
//   }
//   .navbar .navbar-toggler-bar + .navbar-toggler-bar {
//     margin-top: 7px;
//   }
//   .bar2 {
//     opacity: 1;
//   }
//   .bar3 {
//     bottom: 0px;
//     animation: bottombar-back 500ms 0s;
//     animation-fill-mode: forwards;
//   }
//   .sidebar {
//     position: fixed;
//     display: block;
//     top: 0;
//     height: 100%;
//     width: 260px;
//     right: auto;
//     left: 0;
//     margin: 0;
//     border-radius: 0;
//     z-index: 1032;
//     visibility: visible;
//     overflow-y: visible;
//     padding: 0;
//     transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
//     transform: translate3d(-260px, 0, 0);
//   }
//   .nav-open .sidebar {
//     transition: 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
//     transform: translate3d(0px, 0, 0);
//   }
// }

.uploadProfile {
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px;
  cursor: pointer;
  width: 133px;
  height: 133px;
  border-radius: 6px;
  display: block;
  margin: 27px auto 15px;
  position: relative;
  box-shadow: 0 0 2rem 0 rgba(136, 152, 170, 0.15);

  img {
    height: 125px;
    width: 125px;
    border-radius: 4px;
    object-fit: contain;
  }

  i {
    font-size: 16px;
    color: #999;
    width: 32px;
    height: 28px;
    line-height: 26px;
    position: absolute;
    bottom: -13px;
    left: 50.5px;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.1);
    text-align: center;
    border-radius: 6px;
  }
}
.checkBox {
  width: 20px;
  height: 20px;
  margin-right: 15px;
  cursor: pointer;
}

input[type='checkbox']:not(:checked) ~ #sidebarMenu .zoom-slider-wrap {
  display: none;
}

.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 150px;
}

.modal-content {
  position: relative;
}

.resizable-container {
  width: 100% !important;
}

.rawfields {
  height: 500px !important;
  max-height: inherit !important;
}

.jtk-draw-skeleton {
  outline: none;
}

.jtk-draw-handle {
  background-color: #1e1e2f4d;
  width: 5px !important;
  height: 5px !important;
}

.jtk-surface-selected-element {
  border: 1px dotted #1e1e2f4d !important;
}

// .wrapper {
// 	overflow: hidden;
// }
.cardGrid {
  width: 100%;
  flex: 0 0 25%;
  margin-bottom: 30px;
}

.labCard {
  height: 100%;
  .card-header {
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed #333;
    padding-bottom: 15px;
    font-size: 16px;
    font-weight: 500;
  }
  .card-body {
    padding-bottom: 0;
    .list-group {
      .list-group-item {
        background: transparent;
        border: none;
        border-bottom: 1px dashed $lightBlack;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 0;
        display: flex;
        &:last-child {
          border-bottom: none;
        }
        span {
          color: rgba($color: #fff, $alpha: 0.6);
          &:first-child {
            display: inline-block;
            width: 110px;
            position: relative;
            text-transform: capitalize;
            color: rgba($color: #fff, $alpha: 0.9);
            &::after {
              content: ':';
              position: absolute;
              right: 0;
            }
          }
        }
      }
      &.teamCount {
        li {
          span {
            width: auto !important;
            margin-right: 10px;
          }
          button {
            color: $primary;
            text-decoration: underline;
          }
        }
      }
    }
  }
  .dropdown {
    > button {
      padding: 0 8px;
      background: transparent !important;
      font-size: 18px;
      &:focus,
      &:active:focus {
        background: transparent !important;
      }
    }
  }

  .viewBtn {
    padding: 0 18px !important;
  }
  .centerButton {
    .viewBtn {
      padding: 0 24px !important;
      margin: 0 10px;
    }
  }
  &.uploadCard {
    .card-img-top {
      height: 200px;
      object-fit: cover;
    }
    .badge {
      position: absolute;
      left: 10px;
      top: 10px;
      padding: 10px 20px;
      font-size: 16px;
      background-color: $primary;
      color: $white;
    }
    .uploadInfo {
      padding: 0;
      list-style: none;
      li {
        border-bottom: 1px dashed #333;
        padding: 10px 0;
        span {
          margin-right: 5px;
          font-size: 14px;
          color: $white;
          width: auto;
          display: block;
          &:first-child {
            width: 120px;
          }
          &:last-child {
            color: rgba($color: $white, $alpha: 0.7);
          }
        }
      }
    }
    .para {
      color: rgba($color: $white, $alpha: 0.6);
    }
    .actionButton {
      .viewBtn,
      .stopBtn {
        padding: 0px 24px !important;
        height: 36px !important;
        margin: 10px 8px 0px;
        i {
          margin-right: 5px;
        }
      }
    }
    .actionDrop {
      position: absolute;
      right: 10px;
      top: 10px;
      > button {
        background-color: $lightBlack !important;
        padding: 4px 16px;
        &:focus,
        &:hover {
          background-color: $lightBlack !important;
        }
      }
      .dropdown-menu {
        padding: 0;
        min-width: 100px !important;
        .dropdown-item {
          border-bottom: 1px dashed rgba($color: $lightBlack, $alpha: 0.1);
          color: $lightBlack !important;
          font-size: 14px;
          padding: 6px 20px;
          i {
            font-size: 18px;
            margin-right: 6px !important;
          }
          &:last-child {
            border-bottom: none;
            color: #f16667 !important;
          }
        }
      }
    }
  }
}

.appInfo {
  .card-header {
    span {
      font-size: 18px;
      font-weight: 600;
      display: block;
    }
  }
  .license_code {
    text-align: center;
    margin: 30px 0;
    img {
      width: 120px;
      margin: auto;
      display: block;
      margin-bottom: 30px;
    }
    h2 {
      font-size: 18px;
    }
    span {
      color: #f16667;
    }
  }
  .uptodate {
    img {
      width: 100px;
      margin: auto;
      display: block;
      margin-bottom: 30px;
    }
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: 30px 0;
  }
}
.cardLibrary {
  // background-color: #202020 !important;
  background: none !important;
  padding: 20px 10px;
  border-radius: 20px;
  height: 100%;

  .card-img-top {
    // object-fit: cover;
    display: block;
    // margin: auto;
    height: 120px;
    width: auto;
  }
  .card-body {
    padding: 0;
    .card-title {
      text-align: center;
      font-size: 16px;
      font-weight: 500;
      letter-spacing: 0.1px;
      margin: 25px 0;
    }
    .badge {
      width: 80%;
      display: block;
      margin: auto !important;
      font-size: 16px;
      padding: 12px 0;
      border-radius: 20px;
      font-weight: 600;
    }
    .btnInstall {
      background: $primary;
      margin: 0;
      border-radius: 50px;
      margin: auto !important;
      width: 150px;
      &:hover {
        background: rgba($color: $primary, $alpha: 0.8) !important;
      }
    }
  }
}

.userCard {
  height: 100%;
  .card-img-top {
    max-height: 240px;
    object-fit: contain;
  }
  .badge {
    &.badge-success {
      background: rgba(91, 209, 159, 0.6) !important;
      color: $white !important;
    }
    &.badge-danger {
      background: rgba(255, 66, 65, 0.6) !important;
      color: $white !important;
      border-color: rgba(255, 66, 65, 0.6) !important;
    }
    position: absolute;
    right: 15px;
    top: 15px;
    font-size: 14px;
    font-weight: 600 !important;
    padding: 4px 8px;
  }
  .card-body {
    .card-title {
      display: flex;
      justify-content: center;
      font-size: 18px;
      margin-bottom: 10px;
      text-transform: capitalize;
      font-weight: 600;
      span {
        display: inline-block;
        i {
          margin-left: 15px !important;
          display: inline-block;
          cursor: pointer;
          &.edit {
            color: $primary;
          }
          &.delete {
            color: $danger !important;
          }
        }
      }
    }
    .list-group {
      .list-group-item {
        background: transparent;
        border: none;
        border-bottom: 1px dashed $lightBlack;
        padding-top: 10px;
        display: flex;
        padding-bottom: 10px;
        &:last-child {
          border-bottom: none;
        }
        span {
          color: rgba($color: #fff, $alpha: 0.6);
          &:first-child {
            display: inline-block;
            width: 105px;
            position: relative;
            text-transform: capitalize;
            color: rgba($color: #fff, $alpha: 0.9);
            &::after {
              content: ':';
              position: absolute;
              right: 0;
            }
          }
          &:last-child {
            word-break: break-all;
            text-align: right;
          }
        }
      }
      &.teamCount {
        li {
          span {
            width: auto !important;
            margin-right: 10px;
          }
          button {
            color: $primary;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.innerForm {
  .form-group {
    label {
      color: $white !important;
    }
    input:not([type='checkbox']),
    select {
      height: 50px;
      border-radius: 6px;
      color: rgba($color: $white, $alpha: 0.5) !important;
      &::placeholder {
        color: rgba($color: $white, $alpha: 0.5) !important;
      }
      -moz-appearance: none; /* Firefox */
      -webkit-appearance: none; /* Safari and Chrome */
      appearance: none;
    }
    select {
      background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
      background-repeat: no-repeat;
      background-position-x: 98%;
      background-position-y: 12px;
    }
  }
  .input-group {
    border: 1px solid #ced4da !important;
    border-radius: 6px;
    input {
      border: none !important;
      ~ .input-group-append {
        border: none !important;
        .input-group-text {
          border: none;
          i {
            color: $lightBlack;
            font-size: 20px;
          }
        }
      }
    }
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: rgba($color: $lightBlack, $alpha: 0.6) !important;
    box-shadow: 0 0 0 30px #fff inset !important;
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
  }
  &.innerCard {
    .form-group {
      label {
        color: rgba($color: #fff, $alpha: 0.8) !important;
      }
      input,
      select {
        color: rgba($color: #fff, $alpha: 0.5) !important;
        &::placeholder {
          color: rgba($color: #fff, $alpha: 0.5) !important;
        }
      }
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.sidebar,
.off-canvas-sidebar {
  // margin-top: 90px !important;
}
// .main-panel > .content {
// 	padding-top: 90px !important;
// }
.userDetail {
  list-style-type: none !important;
  ul.dropdown-menu {
    li {
      padding: 0;
      button {
        color: $lightBlack !important;
      }
    }
  }
}

.checkBox_inline {
  .form-group {
    margin-bottom: 0 !important;
    .custom-control-label {
      &::before,
      &::after {
        top: 0 !important;
      }
    }
  }
}

.LibraryColumn {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px;
  margin: 0;
  margin-bottom: 30px;
  .col {
    padding: 0;
  }
}

.imageDropdown {
  width: 100%;
  .dropdown-toggle {
    background: transparent !important;
    border: 1px solid #ced4da !important;
    padding: 10px;
    width: 100%;
    text-align: left;
    font-weight: 400;
    &:focus {
      background: transparent !important;
    }
    img {
      width: 24px;
      margin-right: 10px;
    }
  }
  .dropdown-menu {
    padding: 0;
    background-color: $lightBlack;
    width: 100%;
    .dropdown-item {
      padding: 10px;
      cursor: pointer;
      border-bottom: 1px dashed rgba($color: $black, $alpha: 0.4);
      &:last-child {
        border: none;
      }
      img {
        width: 24px;
        margin-right: 10px;
      }
    }
  }
}

// responsive
@media screen and (max-width: 1600px) {
  .cardGrid {
    flex: 0 0 33.33%;
  }
}
@media screen and (max-width: 1200px) {
  .cardGrid {
    flex: 0 0 50%;
  }
}

@media screen and (max-width: 1024px) {
  .login-page {
    .loginRight {
      display: none !important;
    }
    .logoIcon {
      opacity: 0.05;
      right: -18%;
      top: -40px;
      width: 50%;
    }
  }
}
@media screen and (max-width: 1366px) {
  .tableFilterWrap {
    .nav-tabs {
      overflow-y: scroll;
      li {
        a {
          padding: 10px;
          font-size: 14px;
          white-space: nowrap;
        }
      }
    }
  }
}

@media screen and (max-width: 991px) {
  .userDetail ul.dropdown-menu {
    position: absolute !important;
    background: #fff !important;
    margin: 0 !important;
  }
  .userDetail {
    list-style-type: none !important;
    &::marker {
      list-style-type: none !important;
    }
  }
  .userDetail ul.dropdown-menu li.nav-link {
    padding: 0 !important;
  }

  .userDetail ul.dropdown-menu li.nav-link button {
    margin: 0;
  }
  .main-panel > .navbar {
    padding-left: 0;
    padding-right: 0;
  }
  .sidebar,
  .off-canvas-sidebar {
    margin-top: 0px !important;
  }
  .designLabSidebarWrapper {
    z-index: 9999999;
    .sidebarIconToggle {
      top: 24px;
      left: 30px;
    }
    input[type='checkbox']:checked + .sidebarIconToggle {
      top: 24px;
      left: 30px;
    }
    ~ .main-panel {
      z-index: 1;
    }
  }
  .content.designLabSideMenuClosedPadding {
    // padding-left: 85px !important;
  }
}

@media screen and (max-width: 767px) {
  .main-panel > .content {
    // padding-left: 20px !important;
  }

  .cardGrid {
    flex: 1 1 100%;
  }
}
