.App {
  // height: 100vh;
}
.custom-node {
  // border: 1px solid #222;
  position: relative;
  z-index: 9;
}

.connect {
  display: none;
}

.custom-node:hover .connect {
  display: block;
  background-image: url('../assets/img/lan.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 30px;
  height: 30px;
  background-color: transparent;
}

.select-mode {
  cursor: crosshair;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.jtk-surface {
  width: 100%;
  margin: 0 auto;
  // background-size: 20px 20px;
  // background-color: rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 0px),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 0px);
  // background-image: url('../assets/img/header.jpg');
  // background-repeat: no-repeat;
}

.jtk-node {
  /* width: 100px; */
  // color: white;
  text-align: center;
  // padding: 7px;
}

.jtk-overlay {
  background-color: white;
  padding: 7px;
  border-radius: 4px;
}

.jtk-node button {
  margin: 10px;
}

.colorButton {
  position: absolute;
  z-index: 1000;
  top: 50px;
  right: 101px;
  background-color: blue;
  padding: 12px;
  outline: 0;
  border: none;
  color: white;
  border-radius: 7px;
  box-shadow: 2px 2px 10px grey;
}

/* custom */
.editBtn {
  top: 120px;
  right: 101px;
}

.jtk-group.jtk-drag-hover,
.jtk-node.jtk-drag-hover {
  outline: 2px solid red;
}

#drawing {
  background-size: 20px 20px;
  background-color: rgba(0, 0, 0, 0.08);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 1px, transparent 0px),
    linear-gradient(rgba(255, 255, 255, 0.2) 1px, transparent 0px);
}

.zoom-range {
  position: absolute;
  z-index: 1000;
  top: 180px;
  right: 101px;
}

.jtk-surface.jtk-drag-drop-active,
.jtk-node.jtk-drag-drop-active,
.jtk-group.jtk-drag-drop-active {
  outline: 4px solid purple;
}

svg.jtk-drag-drop-active path {
  stroke: purple;
}

.deleteBtn {
  top: 240px;
  right: 101px;
}

.selectModeBtn {
  top: 310px;
  right: 101px;
}

.updateLinkBtn {
  top: 370px;
  right: 101px;
}

.adjust-minimize-icon {
  position: absolute;
  right: 45px;
  top: 20px;
  font-size: 15px;
  color: #000;
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 25%;
  z-index: 1050;
  background: white;
  width: 50%;
}

.minimised-modal-list {
  background: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 10px;
  display: flex;
}

.minimised-modal-item {
  align-items: flex-start;
  margin-right: 10px;
  padding: 5px;
  border: 1px solid #222;
  border-radius: 4px;
}
.jtk-connector {
  z-index: 9;
}

.jtk-hover {
  // filter: drop-shadow(8px 12px 7px rgba(255,165,0,1))
  /* box-shadow: 2px 2px 19px #e0e0e0; */
}

.jtk-draw-skeleton {
  z-index: 10;
}

.controls i {
  background-color: rgba(38, 38, 38, 0.7);
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  border: 1px solid white;
  font-size: 18px;
  font-style: normal;
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 5px 5px;
}
.controls {
  .selected-mode {
    background-color: #f18f3c !important;
    color: #fff !important;
  }
}
