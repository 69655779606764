.card-bordered {
  border: 1px solid #ebebeb;
  /* width: 100vw; */
}

.card {
  border: 0;
  border-radius: 0px;
  margin-bottom: 30px;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.03);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.padding {
  padding: 1rem 0rem !important;
}

body {
  background-color: #f9f9fa;
}

.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-header {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  padding: 15px 20px;
  background-color: transparent;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.card-header .card-title {
  padding: 0;
  border: none;
}

h4.card-title {
  font-size: 17px;
}

.card-header > *:last-child {
  margin-right: 0;
}

.card-header > * {
  margin-left: 8px;
  margin-right: 8px;
}

.btn-secondary {
  /* color: #4d5259 !important; */
  /* background-color: #e4e7ea; */
  border-color: #e4e7ea;
  color: #fff;
}

.btn-xs {
  font-size: 11px;
  padding: 2px 8px;
  line-height: 18px;
}
.btn-xs:hover {
  color: #fff !important;
}

.card-title {
  /* font-family: Roboto, sans-serif; */
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 0;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(77, 82, 89, 0.07);
}

.ps-container {
  position: relative;
}

.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.media .avatar {
  flex-shrink: 0;
}

.avatar {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  background-color: #f5f6f7;
  color: #8b95a5;
  text-transform: uppercase;
}

.media-chat .media-body {
  -webkit-box-flex: initial;
  flex: initial;
  display: table;
  width: 80%;
}

.media-body {
  min-width: 0;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #f5f6f7;
  border-radius: 3px;
  font-weight: 100;
  color: #fff;
}

.media > * {
  margin: 0 8px;
}

.media-chat .media-body p.meta {
  background-color: transparent !important;
  padding: 0;
  opacity: 0.8;
}

.media-meta-day {
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 0;
  color: #8b95a5;
  opacity: 0.8;
  font-weight: 400;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.media-meta-day::before {
  margin-right: 16px;
}

.media-meta-day::before,
.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  content: '';
  -webkit-box-flex: 1;
  flex: 1 1;
  border-top: 1px solid #ebebeb;
}

.media-meta-day::after {
  margin-left: 16px;
}

.media-chat.media-chat-reverse {
  padding-right: 12px;
  padding-left: 64px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  flex-direction: row-reverse;
}

.media-chat {
  padding-right: 64px;
  margin-bottom: 0;
}

.media {
  padding: 16px 12px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
}

.media-chat.media-chat-reverse .media-body p {
  float: right;
  clear: right;
  background-color: #f38909;
  color: #fff;
}

.media-chat .media-body p {
  position: relative;
  padding: 6px 8px;
  margin: 4px 0;
  background-color: #feb904;
  border-radius: 3px;
}

.border-light {
  border-color: #f1f2f3 !important;
}

.bt-1 {
  border-top: 1px solid #ebebeb !important;
}

.publisher {
  position: relative;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 12px 20px;
  border: 2px solid #5f5555 !important;
  border-radius: 10rem !important;
  /* background-color: #f9fafb; */
}

.copybtn {
  visibility: hidden;
}

.copybtn:hover {
  visibility: hidden;
}

.publisher > *:first-child {
  margin-left: 0;
}

.publisher > * {
  margin: 0 8px;
}

.publisher-input {
  -webkit-box-flex: 1;
  flex-grow: 1;
  border: none;
  color: white;
  /* font-weight: bold; */
  outline: none !important;
  background-color: transparent;
}

button,
input,
optgroup,
select,
textarea {
  /* font-family: Roboto, sans-serif; */
  font-weight: 300;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #8b95a5;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.file-group {
  position: relative;
  overflow: hidden;
}

.publisher-btn {
  background-color: transparent;
  border: none;
  color: #cac7c7;
  font-size: 16px;
  cursor: pointer;
  overflow: -moz-hidden-unscrollable;
  -webkit-transition: 0.2s linear;
  transition: 0.2s linear;
}

.file-group input[type='file'] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  width: 20px;
}

.text-info {
  color: #48b0f7 !important;
}

.overFLOW {
  overflow-y: scroll !important;
  height: 450px !important;
}

.no-overFLOW {
  /* overflow: hidden !important; */
  /* overflow-y: hidden !important;
  overflow-x: hidden !important; */
}

.polaroid {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  text-align: center;
}

.bg-color-lite {
  background-color: #d8d8d8;
}

.customChatContainer {
  width: 80vw !important;
}

.copy-icon {
  transition: all 0.2s;
}

.copy-icon:active {
  /* background-color: gray; */
  /* border-radius: 10rem; */
}

.copy-icon:hover {
  filter: brightness(1.05);
  transform: translateY(-5px);
  /* box-shadow: 0px 30px 200px rgba(255, 255, 255, 0.7); */
}

@font-face {
  font-family: 'SamuraiFont';
  src: url('./mrrobot.ttf') format('truetype');
  /* src: url('./nothing.ttf') format('truetype'); */
}

.nothing-font {
  font-family: 'SamuraiFont', sans-serif;
}

.btn-and-loader {
  display: flex !important;
}
.tl-update-loader {
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  padding: 0.8rem;
}

.privacy-btn {
  border: none !important;
}

.float-right {
  float: right;
}
.teams-container img {
  width: fit-content;
  margin: 0 auto;
}
.jtk-demo-canvas {
  height: 88vh !important;
}
.footer {
  position: absolute !important;
  bottom: 0 !important;
  margin: 0 !important;
  left: 0;
  right: 0;
  padding: 0 !important;
}

.teams-container .MuiCardHeader-content {
  color: #ebebeb !important;
}

.teams-container .MuiCardHeader-subheader {
  color: #ebebeb !important;
}

.teams-container .MuiSvgIcon-root {
  color: #ebebeb !important;
}
.teams-container .MuiChip-filled {
  color: #ebebeb !important;
}
.h-v_center {
  /* position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
  float: right;
}
.licenseDetails {
  display: inline-block;
  padding: 0.7rem 1rem;
  border-radius: 10px;
  margin-bottom: 5px;
  background-color: #1f1d1d;
  color: rgb(163, 193, 217);
}
.license_d-flex {
  display: flex;
  flex: 1 1 calc(25% - 30px);
}
.license-details {
  /* border: 3px solid rgb(67, 63, 63); */
  padding: 10px;
  margin: 0 auto;
}
.license-details p {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.browse {
  border-left: 2px solid gray;
  background-color: gray;
  padding: 1rem;
  position: relative;
  right: -1rem;
}

.license_modal .modal-content {
  width: 70% !important;
  margin: auto !important;
}
