.plus {
  position: absolute;
  height: 2rem;
  width: 2rem;
  transition: 0.2s ease-in-out;
  margin: 0 auto;
  cursor: pointer;
  margin-left: 87%;
  /* margin: 1rem; */
  /* background: #ffb400 !important; */
  /* padding: ; */
}
.plus:hover {
  transform: rotate(45deg);
}
