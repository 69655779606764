#toggle-switch {
  width: 50px;
  height: 25px;
  border-radius: 25px;
  border: none;
  background-color: #ddd;
  position: relative;
  transition: background-color 0.2s;
}

#toggle-switch::before {
  content: '';
  position: absolute;
  top: 2.5px;
  left: 2.5px;
  width: 20px;
  height: 20px;
  border-radius: 20px;
  background-color: #777;
  transition: transform 0.2s;
}

#toggle-switch.active {
  background-color: #f18f3c;
}

#toggle-switch.active::before {
  transform: translateX(25px);
}

.visibleToggle {
  display: block !important;
}

#api {
  display: none;
}

.apiKeyCard {
  padding-left: 0 !important;
}

.centerChat {
  margin: 0 auto;
}
