.cardContainer {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); */
  margin-top: 1.7rem;
  /* grid-gap: 100px; */
}

.d-inline {
  display: inline !important;
}

.center-text {
  text-align: center;
}

.main-color {
  color: #d0cdcb !important;
  font-weight: 600;
}

.labCard {
  /* background-color: #242424; */
  background: none;
  border: 1px solid #222322;
  text-align: center;
  border-radius: 8px;
  padding: 20px 0;
  margin-left: 2rem;
  margin-bottom: 2rem;
  border-radius: 9px;
  padding: 1rem;
  /* border: 2px solid gray; */
  width: 27vw;
  transition: 0.5s;
}
.labCard:hover {
  /* box-shadow: inset 5px 5px 8px rgb(49, 47, 47), inset -5px -5px 8px rgb(49, 47, 47); */
  /* transform: translateY(-20px); */
  transform: scale(1.05);
}
@media only screen and (max-width: 796px) {
  .labCard {
    width: 42vw;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 650px) {
  .labCard {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    width: 90vw;
  }
}
/* .card .img-wrapper {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
}
.card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */
