/*
 =========================================================
 * Black Dashboard React v1.1.0 based on Black Dashboard - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2020 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

 * Coded by Creative Tim

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */

// Core

@import "black-dashboard-react/custom/functions";
@import "black-dashboard-react/bootstrap/functions";

@import "black-dashboard-react/custom/variables";
@import "black-dashboard-react/bootstrap/variables";

@import "black-dashboard-react/custom/mixins";
@import "black-dashboard-react/bootstrap/mixins";

// Bootstrap components

@import "black-dashboard-react/bootstrap/root";
@import "black-dashboard-react/bootstrap/reboot";
@import "black-dashboard-react/bootstrap/type";
@import "black-dashboard-react/bootstrap/images";
@import "black-dashboard-react/bootstrap/code";
@import "black-dashboard-react/bootstrap/grid";
@import "black-dashboard-react/bootstrap/tables";
@import "black-dashboard-react/bootstrap/forms";
@import "black-dashboard-react/bootstrap/buttons";
@import "black-dashboard-react/bootstrap/transitions";
@import "black-dashboard-react/bootstrap/dropdown";
@import "black-dashboard-react/bootstrap/button-group";
@import "black-dashboard-react/bootstrap/input-group";
@import "black-dashboard-react/bootstrap/custom-forms";
@import "black-dashboard-react/bootstrap/nav";
@import "black-dashboard-react/bootstrap/navbar";
@import "black-dashboard-react/bootstrap/card";
@import "black-dashboard-react/bootstrap/breadcrumb";
@import "black-dashboard-react/bootstrap/pagination";
@import "black-dashboard-react/bootstrap/badge";
@import "black-dashboard-react/bootstrap/jumbotron";
@import "black-dashboard-react/bootstrap/alert";
@import "black-dashboard-react/bootstrap/progress";
@import "black-dashboard-react/bootstrap/media";
@import "black-dashboard-react/bootstrap/list-group";
@import "black-dashboard-react/bootstrap/close";
@import "black-dashboard-react/bootstrap/modal";
@import "black-dashboard-react/bootstrap/tooltip";
@import "black-dashboard-react/bootstrap/popover";
@import "black-dashboard-react/bootstrap/carousel";
@import "black-dashboard-react/bootstrap/utilities";
@import "black-dashboard-react/bootstrap/print";

// Custom components

@import "black-dashboard-react/custom/alerts.scss";
@import "black-dashboard-react/custom/badges.scss";
@import "black-dashboard-react/custom/buttons.scss";
@import "black-dashboard-react/custom/dropdown.scss";
@import "black-dashboard-react/custom/footer.scss";
@import "black-dashboard-react/custom/forms.scss";
@import "black-dashboard-react/custom/images.scss";
@import "black-dashboard-react/custom/modal.scss";
@import "black-dashboard-react/custom/navbar.scss";
@import "black-dashboard-react/custom/type.scss";
@import "black-dashboard-react/custom/tables";
@import "black-dashboard-react/custom/checkboxes-radio";
@import "black-dashboard-react/custom/fixed-plugin";
@import "black-dashboard-react/custom/sidebar-and-main-panel.scss";
@import "black-dashboard-react/custom/misc.scss";
@import "black-dashboard-react/custom/rtl.scss";
@import "black-dashboard-react/custom/input-group.scss";

// Vendor / Plugins

@import "black-dashboard-react/custom/vendor/plugin-perfect-scrollbar.scss";
@import "black-dashboard-react/custom/vendor/plugin-react-notification-alert.scss";

// light mode

@import "black-dashboard-react/custom/white-content.scss";

// Cards

@import "black-dashboard-react/custom/card";
@import "black-dashboard-react/custom/cards/card-chart";
@import "black-dashboard-react/custom/cards/card-map";
@import "black-dashboard-react/custom/cards/card-user";
@import "black-dashboard-react/custom/cards/card-task";
@import "black-dashboard-react/custom/cards/card-plain";

// React differences
@import "black-dashboard-react/react-differences/react-differences";

// kailash

$orange: #eb8a0d;
.uploadfiles {
    padding: 0;
    margin-right: -8px;
    margin-left: -8px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    grid-gap: 15px;
    li {
        text-align: center;
        list-style: none;
        position: relative;
        // margin-right: 15px;
        // margin-bottom: 15px;
       
        &.fileUoloadButton{
            border-color: transparent;
        }
        &.noData{
            width: 100%;
            height: auto;
            display: block;
            margin: 0;
            padding: 0;
            border:none;
            color: #333 !important;
            &:first-child{
                grid-column: 1 / -1;
            }
        }
        .storage_file {
            border-radius: 4px;
            border: 1px solid #ddd;
            width: 100px;
            height: 100px;
            margin:auto;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $white;
            overflow: hidden;
            &.not_image {
                background-color: #b1b1b126 !important;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: 0.5s ease-in-out;
            }
            i {
                font-size: 30px;
                color: $black;
                transition: 0.5s ease-in-out;
            }
            i.fa-times {
                position: absolute;
                top: -5px;
                right: -3px;
                z-index: 9;
                width: 20px;
                height: 20px;
                background-color: $white;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50px;
                font-size: 12px;
                cursor: pointer;
                box-shadow: 3px -3px 5px 0 rgba($color: #000000, $alpha: 0.1),
                            -3px 3px 5px 0 rgba($color: #000000, $alpha: 0.1);
                &:hover {
                    color: red !important;
                }
                &::after {
                    display: none;
                }
            }
            &:hover {
                img,
                i {
                    transform: scale(1.2);
                }
            }
        }

        .content {
            p {
                margin: 0;
                padding-top: 6px;
                font-weight: 500;
            }
        }

        .ChooseFile {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            border: 1px solid #ddd;
            margin: 0;
            border-radius: 4px;
            cursor: pointer;
            i {
                font-size: 20px;
                color: $black;
                color: gray;
            }
        }
    }
}

.truncate {
	word-break: break-all;
}
.btn-outline-primary {
    background: transparent !important;
    color: $orange !important;
    &:hover,
    &:focus,
    &:active:focus {
        background: $orange !important;
        color: $white !important;
    }
}
.modal-content .modal-header {
    position: relative;
}
.modal-minimize {
    background: transparent;
    height: auto;
    padding: 10px !important;
    position: absolute !important;
    right: 60px !important;
    top: 24px !important;
    color: $black;
    border-radius: 0;
    &:hover,
    &:focus,
    &:active:focus,
    &:active {
        background: transparent !important;
        color: $black !important;
    }
}

.minmize_modal_list {
    position: absolute;
    bottom: 0;
    overflow-x: auto;
    right: 0;
    left: 0;
    width: calc(100% - 280px);
    margin-left: auto;
    direction: rtl;
    // &::-webkit-scrollbar{
    //     width: 2px;
    // }
    // &::-webkit-scrollbar-track{
    //     background: #f1f1f1;
    //     border-radius: 10px;
    // }
    // &::-webkit-scrollbar-thumb {
    //         background: rgba($orange, 0.8);
    //         height: 5px;
    //         width: 6px;
    //         border-radius: 10px;
    //         &:hover {
    //             background:$orange;
    //         }
    //     }
    .minmize_item {
        background-color: $white;
        padding: 14px 12px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
        width: 200px;
        margin: 0 4px;
        justify-content: space-between;
        direction: ltr;

        .title {
            h1 {
                font-size: 16px;
                font-weight: 600;
                letter-spacing: 0;
                margin: 0 !important;
                max-width: 120px;
                white-space: nowrap;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        .minimize_icon {
            margin-left: 20px;
            display: flex;
            button {
                padding: 0px 0 0 15px !important;
                line-height: 0;
                background: transparent !important;
                margin: 0;
                &:first-child {
                    padding-left: 0 !important;
                }
                i {
                    font-size: 12px;
                    color: $black;
                    &::after {
                        display: none;
                    }
                }
            }
        }
    }
}

.checkBox_inline {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 15px;
}

.checkBox_inline .form-group {
    font-size: 30px;
    margin-bottom: 6px !important;
}

.checkBox_inline .form-group:nth-child(1) {
    margin-right: 35px;
}

.nodrag_inner {
    .draggable {
        transform: translate(0, 0) !important;
    }
}
