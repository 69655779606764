.labSidebar {
  position: absolute !important;
  top: 95% !important;
  right: 95% !important;
}

.bottom-nav {
  background: transparent !important;
}

.labSidebar .iSpeedDial-fab {
  background-color: red;
}

/* MuiButtonBase-root MuiFab-root MuiFab-circular MuiFab-sizeLarge MuiFab-primary MuiFab-root MuiFab-circular MuiFab-sizeLarge MuiFab-primary MuiSpeedDial-fab css-7dv1rb-MuiButtonBase-root-MuiFab-root-MuiSpeedDial-fab */
