tr > * {
  color: white !important;
  font-family: 'Roboto Mono', monospace !important;
}

.false {
  color: rgb(239, 94, 94) !important;
}

thead > tr > th {
  background-color: rgb(42, 42, 43) !important;
  font-family: 'Roboto Mono', monospace !important;
  font-weight: 500 !important;
  font-size: 1rem !important;
}
