/* .templateLibraryContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
}

.templateLibraryContainer_element {
} */

/* .cardContainer-Lib {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
}

.d-inline {
  display: inline !important;
}

.lib-imgWidth {
  width: 100%;
  margin: 0 auto;
}
.center-text {
  text-align: center;
}

.main-color {
  color: #f18f3c !important;
  font-weight: 600;
}

.libraryName {
  font-size: 1.2rem !important;
}

.labCard-Lib {
  background-color: #242424 !important;
  text-align: center;
  border-radius: 8px;
  padding: 20px 0;

  border-radius: 9px;
  width: 15vw;
  transition: 0.5s;
}
.labCard-Lib:hover {
  box-shadow: inset 5px 5px 8px rgb(49, 47, 47), inset -5px -5px 8px rgb(49, 47, 47);
  transform: translateY(-20px);
}
@media only screen and (max-width: 796px) {
  .labCard-Lib {
    width: 42vw;
    margin-left: 2rem;
    margin-bottom: 2rem;
  }
}
@media only screen and (max-width: 650px) {
  .labCard-Lib {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    width: 90vw;
  }
} */

.container-TL {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
  margin: 0;
  padding: 0;
  color: #f6f3ea;
  transition: 0.5s;
}

.item-TL {
  padding: 1em;
  background-color: #272727 !important;
  text-align: center !important;
  height: fit-content;
  transition: 0.5s;
  min-height: 40vh;
}
.libraryName {
  font-size: 1.2rem !important;
}

.item-TL:hover {
  transform: scale(1.05);
  cursor: pointer;
  box-shadow: 0px 12px 30px 0px rgba(0, 0, 0, 0.2);
  transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
}

/* //// */
.boolean-input {
  display: inline-block;
  width: 30px !important;
}

.lib-imgWidth {
  width: 50%;
  margin: 0 auto;
}

/* ===================================================================== */

.centerMe {
  /* transform: translate(-50%, -50%); */
  /* margin-top: 3rem; */
  margin-top: 1rem !important;
  margin: 0 auto;
}

.button-del {
  display: flex;
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: 0;
  overflow: hidden;
}
.button-del .icon {
  /* position: relative; */
  background: #1d242b;
  line-height: 30px;
  width: 30px;
  height: 30px;
  text-align: center;
  color: #fff;
  font-size: 18px;
  transition: 0.2s color;
  border-radius: 2px;
}
.button-del .icon .fa {
  width: 30px;
  transition: 0.2s all;
}
.button-del .icon .fa-check {
  color: #38b87c;
}
.button-del .icon .fa-question {
  color: #2492ff;
}
.button-del .icon:after {
  content: ' ';
  display: block;
  width: 0;
  height: 0;
  transform: rotate(45deg);
  background: #1d242b;
  transition: 0.2s right;
  z-index: 1;
}
.button-del .text {
  position: relative;
  width: 0;
  height: 30px;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
  background: #f34541;
  text-align: center;
  line-height: 30px;
  color: #fff;
  font-weight: 300;
  transition: 0.2s all;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}
.button-del .text span {
  width: 100%;
  opacity: 0;
  /* position: absolute;
  top: -30px;
  left: 50%; */
  transform: translateX(-50%);
  transition: 0.3s all;
}
.button-del:hover .icon {
  color: #f34541;
  border-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.button-del:hover .icon:after {
  right: -2px;
}
.button-del:hover .text {
  width: 120px;
}
.button-del:hover .text span {
  opacity: 1;
  top: 0;
}
.button-del.confirm .icon {
  border-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.button-del.confirm .icon .fa {
  transform: translateY(-30px);
}
.button-del.confirm .icon:after {
  right: -2px;
}
.button-del.confirm .text {
  background: #2492ff;
  width: 120px;
}
.button-del.confirm .text span {
  opacity: 1;
  top: 0;
}
.button-del.done .icon {
  border-radius: 0;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;
}
.button-del.done .icon .fa {
  transform: translateY(-60px);
}
.button-del.done .icon:after {
  right: -2px;
}
.button-del.done .text {
  background: #38b87c;
  width: 120px;
}
.button-del.done .text span {
  opacity: 1;
  top: 0;
}

@-webkit-keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes fadeInZoom {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.download-json-template:hover {
  border: 2px solid #272626 !important;
}