.bg-dark {
  background-color: #171717 !important;
}

.color-scheme__modal {
  background-color: #242424 !important;
  color: whitesmoke !important;
}

.modal-content {
  background-color: #232323 !important;
}

.modal-title {
  color: white !important;
}

.modal-content .modal-body p {
  color: white !important;
}
.custom-context-menu {
  background-color: #171717 !important;
}

.custom-context-menu > li {
  color: white !important;
}

.custom-context-menu > li > .disable-action {
  color: white !important;
}

.custom-context-menu > li:hover {
  color: #171717 !important;
}

.custom-context-menu > li > .disable-action:hover {
  color: #171717 !important;
}

.deleteTemplate_btn {
  color: #171717;
}

.modal-header + .innerForm .form-group label {
  color: white !important;
}

.modal-header + .innerForm .form-group input:not([type='checkbox']),
.innerForm .form-group select {
  color: white !important;
}

.userCard .card-body .card-title span i.edit {
  height: 32px !important;
}

.userCard:hover {
  transform: scale(1.05);
}
