// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';
// copied from web app
@import './scss/web-app.scss';

// Scroll css start
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: #d0d0d0;
  // border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #8c8c8c;
}
// Scroll css end

.custom-context-menu {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    padding: 7px 10px;
    cursor: pointer;
    color: #222;
    border-bottom: 1px solid #cccc;
    &:hover {
      background-color: #cccc;
    }
    &:last-child {
      border-bottom: 0;
    }
  }
}

.lab-name {
  position: absolute;
  left: 40%;
  font-size: 24px;
  color: #f6bb44;
}
.editor-wrapper {
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
}

svg {
  // z-index: 99;
}

rect {
  // opacity: 0.5;
}

// .svg_select_boundingRect {
//   pointer-events: none !important;
//   /* important added */
// }

#myDrawing {
  svg {
    // pointer-events: none !important;
  }
  circle {
    // opacity: 0.5;
  }
}

.import-btn {
  position: relative;
  height: 38px;
  cursor: pointer;
  .file-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.disable-action {
  opacity: 0.5;
  background: #dddddd78;
  pointer-events: none;
}

.lab-edit-icon {
  color: #f5ab41;
  cursor: pointer;
  // margin-left: 10px;
  margin-right: 1rem;
  float: right;
}

.custom-select-section {
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

// .del-label{
//   display: none;
// }

// .connecttion{
//     // z-index: 999999;
//     &:hover + .del-label{
//         display: block;
//       }
// }

.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  // background: #fff;
  color: #222;
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}

@media screen and (min-width: 991px) {
  .navbar .navbar-brand {
    margin-top: 15px;
  }
}

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  margin-left: -1px;
  line-height: 1.25;
  color: #ffff;
  background-color: #171717;
  border: 1px solid #f18f3c;
}
.page-item.disabled .page-link {
  background-color: transparent;
  border-color: #f18f3c;
}

.page-link:hover {
  z-index: 2;
  color: #27293d;
  text-decoration: none;
  background-color: #f18f3c;
  border-color: #f18f3c;
}

.page-item.active .page-link {
  z-index: 1;
  color: #ffffff;
  background-color: #f18f3c;
  border-color: #f18f3c;
}

.zoom-slider-wrap {
  position: absolute;
  left: 25px;
  bottom: 15px;
  display: flex;
}

.adv-check-wrap {
  display: flex;
  justify-content: flex-end;
  .checkbox-wrap {
    margin-top: 2px;
  }
}

.group-by-wrap {
  // text-align: right;
  margin-bottom: 20px;
  select {
    width: 100px;
    display: inline-block;
  }
}

// theme change
.sidebar[data='blue'] {
  background: linear-gradient(0deg, #151515 0%, #151515 100%);
}

body {
  // background: linear-gradient(0deg, #232323 0%, #2a2a2a 100%);
  background-color: #141414;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Roboto Mono', monospace;
}

.main-panel {
  // background: linear-gradient(#232323, #2a2a2a);
  background: #141414;
}

.card {
  // background: #171717;
  background: none;
  border: 1px solid #222322;
}

.modal {
  // color: #222;
  .page-link {
    background-color: #fff;
    color: #222;
  }
}

.dashboard-docker-icon {
  width: 37px;
  margin: 0 auto;
}

.lab-menu-img {
  width: 30px;
  margin-right: 12px;
}

// conection

.connection-info-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  .info {
    text-align: center;
    width: 30%;
    position: relative;
    i {
      font-size: 40px;
    }
  }
}

.port-form-wrap {
  display: flex;
  justify-content: space-between;
  .form-group {
    width: 45%;
  }
}

.delete-link-icon {
  color: #e68539;
  cursor: pointer;
  text-decoration: underline;
}

@keyframes beat {
  to {
    transform: scale(1.1);
  }
}

.processing-node-wrap {
  animation: beat 0.25s infinite alternate;
  pointer-events: none;
}

.custom-guacamole-modal {
  z-index: 9999;
  border-radius: 20px;
  box-shadow: 10px 5px 30px #222;
  .body {
    background-color: #fff;
  }
  .modal-footer {
    background-color: #fff;
    color: #222;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}

.action-menu-img {
  width: 22px;
  margin: 0px -3px 0 -3px;
  float: left;
}

.color-preview {
  display: inline-block;
  width: 12px;
  height: 12px;
  border: 1px dashed rgb(34, 34, 34);
  margin: -1px 0 0 10px;
  vertical-align: middle;
}

.change-pwd-eye-icon {
  position: absolute;
  right: 10px;
  top: 36px;
  cursor: pointer;
  color: #f18f3c;
  font-size: 16px;
}

.profile-item-wrap {
  margin: 0;
  .profile-item {
    border: 1px solid #ced4da;
    text-align: center;
    margin: 0 10px 10px 0;
    width: 75px;
    height: 75px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 4px;
    flex-direction: column;
    padding: 5px;
    transition: all 0.2s;
    cursor: pointer;
    font-size: 12px;
    position: relative;
    .profile-name {
      line-height: 16px;
      margin-top: 8px;
    }
  }
  .active-profile-item {
    border-color: #f18f3c;
    color: #f18f3c;
  }
}

.emulation-fields {
  display: flex;
  justify-content: space-between;
  .form-group {
    width: 47%;
  }
}

.emulation-item-wrap {
  display: flex;
  justify-content: space-between;
  border: 1px solid #f18f3c;
  border-radius: 4px;
  padding: 9px;
}

.emulation-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.emulation-del-icon {
  justify-content: center;
  color: red;
  cursor: pointer;
  font-size: 18px;
}

.remove-profile-icon {
  position: absolute;
  top: -6px;
  right: -6px;
  color: red;
  font-size: 15px;
  background: #fff;
}

.custom-tag {
  position: absolute;
  width: 18px;
  left: 0px;
  top: -3px;
}

.side-menu-img {
  width: 25px;
  margin: 4px 12px 0 6px;
  float: left;
}

.flexible-modal-drag-area-right,
.flexible-modal-drag-area-left {
  display: none;
}

body.no-scroll {
  overflow: hidden;
}

@keyframes blink {
  50% {
    border-color: #f18f3c;
    border-width: 4px;
  }
}

.active-guacamole-modal {
  // animation: blink .5s step-end infinite alternate;
  border-color: #f18f3c;
  border-width: 4px;
}

.adjust-minimize-icon {
  position: absolute;
  right: 45px;
  top: 20px;
  font-size: 15px;
  color: #000;
  cursor: pointer;
  z-index: 9;
}

.otp-text {
  color: #222;
}

.full-page-modal {
  max-width: calc(100vw - 10%) !important;
}
.full-page-modal .modal-body {
  padding: 2px !important;
}
.drawing-board-container {
  width: 100% !important;
}
.drawing-board-toolbar-iconLabel {
  line-height: 13px;
}
.drawing-board-toolbar-icon:last-child {
  display: none;
}

.drawing-item-wrap {
  height: 100vh;
  h4 {
    color: white !important;
  }
}
.drawing-item {
  padding: 10px;
  border: 1px solid orange;
  margin: 10px;
  border-radius: 10px;
  width: 23%;
  display: inline-block;
  vertical-align: top;
  // height: 260px;
  img {
    // height: 235px;
    object-fit: contain;
  }
}

.drawing-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  h4 {
    color: #222 !important;
  }
  i {
    cursor: pointer;
  }
}

.drawing-name {
  width: 250px;
  margin: 10px;
  // color: #222;
  &:focus {
    // color: #222 !important;
  }
}

.no-drawings {
  text-align: center;
  padding-top: 20%;
}

.footer {
  .orange-text {
    color: #f6bb44 !important;
  }
}
