/* ---------------------------------------------------------------------------------------------------- */
/* --- page structure --------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.jtk-demo-main {
  display: flex;
  /* height:100vh; */
  height: calc(100vh - 141px);
}

.jtk-demo-inner {
  display: flex;
}

.jtk-demo-main .description {
  font-size: 13px;
  padding: 13px;
}

.jtk-demo-main .description ul {
  margin: 0;
  padding: 0 0 0 20px;
}

.jtk-demo-main .description li {
  list-style-type: disc !important;
  margin: 3px 0;
}

.jtk-demo-canvas {
  background-color: white;
  display: flex;
  flex-grow: 1;
  position: relative;
}

.jtk-demo-rhs {
  display: flex;
  flex-direction: column;
  margin: 0;
  background-color: #f9f9f9;
  flex: 0 0 250px;
  padding: 20px 5px 0 5px;
}

.canvas-wide {
  margin-left: 0;
}

.miniview {
  position: absolute;
  top: 25px;
  right: 25px;
  z-index: 100;
}

.jtk-demo-dataset {
  overflow: auto;
  font-size: 12px;
  font-family: Courier, monospace;
}

.jtk-demo-dataset pre {
  white-space: pre-wrap;
}

.demo-title {
  float: left;
  font-size: 18px;
}

.controls {
  top: 25px;
  color: #fff;
  margin-right: 10px;
  position: absolute;
  left: 90%;
  z-index: 1;
  display: flex;
  font-size: 14px;
}

.controls i {
  background-color: #5184a0;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 4px;
  border: 1px solid white;
  font-size: 22px;
  font-style: normal;
  width: 24px;
  height: 24px;
}

.controls i:not(.selected-mode):hover {
  background-color: white;
  color: #5184a0;
  border: 1px solid #5184a0;
}

.controls i:not(.selected-mode):active {
  color: white;
  background-color: #1d9ea0;
  border: 1px solid white;
}

[undo],
[redo] {
  background-color: darkgray !important;
}
[undo]:hover,
[redo]:hover {
  background-color: darkgray !important;
  color: white !important;
  border: 1px solid white !important;
}
[can-undo='true'] [undo],
[can-redo='true'] [redo] {
  background-color: #3e7e9c !important;
}
[can-undo='true'] [undo]:hover,
[can-redo='true'] [redo]:hover {
  background-color: white !important;
  color: #5184a0 !important;
  border: 1px solid #5184a0 !important;
}

/* ------------------------ node palette -------------------- */

.node-palette {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jtk-demo-sidebar {
  margin: 0;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sidebar-item {
  background-color: #ccc;
  border-radius: 11px;
  color: #585858;
  cursor: move;
  padding: 8px;
  width: 128px;
  text-align: center;
  margin: 10px;
  outline: none;
}

.sidebar-item.katavorio-clone-drag {
  margin: 0;
  border: 1px solid white;
}

.sidebar-item:hover,
.sidebar-item.katavorio-clone-drag {
  background-color: #5184a0;
  color: white;
}
/* ---------------------------------------------------------------------------------------------------- */
/* --- jsPlumb setup ---------------------------------------------------------------------------------- */
/* ---------------------------------------------------------------------------------------------------- */

.jtk-surface-pan {
  display: none;
}

.jtk-connector {
  z-index: 9;
}

.jtk-connector:hover,
.jtk-connector.jtk-hover {
  z-index: 10;
}

.jtk-endpoint {
  z-index: 12;
  opacity: 0.8;
  cursor: pointer;
}

.jtk-overlay {
  background-color: white;
  color: #434343;
  font-weight: 400;
  padding: 4px;
  z-index: 10;
}

.jtk-overlay.jtk-hover {
  color: #434343;
}

path {
  cursor: pointer;
}

.edit:hover {
  color: #ff8000;
}

.selected-mode {
  color: #e4f013;
}

.connect {
  width: 10px;
  height: 10px;
  background-color: #f76258;
  position: absolute;
  bottom: 13px;
  right: 5px;
}

/* header styles */

.demo-links {
  position: fixed;
  right: 0;
  top: 57px;
  font-size: 11px;
  background-color: white;
  opacity: 0.8;
  padding-right: 10px;
  padding-left: 5px;
  text-transform: uppercase;
  z-index: 100001;
}

.demo-links div {
  display: inline;
  margin-right: 7px;
  margin-left: 7px;
}

.demo-links i {
  padding: 4px;
}
/*
.jtk-node {
    border:1px solid;
    background-color: white;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    position: absolute;
    z-index: 11;
    overflow: hidden;
    min-width:80px;
    min-height:30px;
    width: auto;
    justify-content: center;
    align-items: center;
    display: flex;
}

.jtk-node .name {
    cursor: move;
    font-size: 13px;
    text-align: center;
}

.jtk-node .name span {
    cursor:pointer;
}

*/

/* icons */

.jtk-node .add,
.jtk-node .delete,
.jtk-node .node-edit {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
  padding-bottom: 2px;
}

.jtk-node .add:hover,
.jtk-node .delete:hover,
.jtk-node .node-edit,
.jtk-node .add.constant,
.jtk-node .delete.constant {
  color: white;
}

.jtk-node .add {
  right: 3px;
}

.jtk-node .add:after {
  content: '+';
}

.jtk-node .add:hover,
.jtk-node .add.constant {
  background-color: #4ea758;
}

.jtk-node .delete {
  left: 3px;
}

.jtk-node .delete:after {
  content: 'x';
}

.jtk-node .delete:hover,
.jtk-node .delete.constant {
  background-color: #d83f3f;
}

.jtk-node .node-edit:after {
  /* content:"✎"; */
}

.fa {
  /* display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal; */
}

.fa-plus:after {
  /* content:"+"; */
  margin-bottom: 0.2rem;
}

.fa-home:after {
  /* content:"⌂"; */
  margin-bottom: 0.4rem;
}

.fa-pencil:after {
  /* content:"✎"; */
}

.fa-arrows:after {
  /* content:"✥"; */
}

.fa-undo:after {
  content: '↺';
}

.fa-repeat:after {
  content: '↻';
}

.fa-times:after {
  content: 'x';
}
