.toolbar-chatBtn .btn-chat-live {
  position: relative;
  /* padding: 13px 25px; */
  padding: 7px 15px;
  /* background-color: #ffb500; */
  background-color: #433f3f;
  text-decoration: none;
  color: #333;
  border-radius: 15px;
  /* border: 1px solid white; */
  /* box-shadow: 0px 2px 0px #02679e; */
  transition: all 0.2s;
  z-index: 1;
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
.toolbar-chatBtn .btn-chat-live:hover {
  /* transform: translateY(-5px); */
  /* padding: calc(13px * 1.2) calc(25px * 1.2); */
  /* padding-right: calc(22px * $btn-chat-livePaddingScalePlus); */
  /* background-color: yellow; */
  /* border: none; */
  /* box-shadow: 0px 5px 10px #0271ae; */
}
.toolbar-chatBtn .btn-chat-live:hover > span:before {
  -webkit-animation: none;
  animation: none;
}
.toolbar-chatBtn .btn-chat-live:active {
  transform: translateY(0px);
  padding: calc(13px * 0.95) calc(25px * 0.95);
  /* box-shadow: inset 0px 2px 1px #02679e; */
}

.toolbar-chatBtn .btn-chat-live_transparent {
  border: 0px;
  border-radius: 0px;
  padding: 13px 25px;
  background-color: rgba(255, 255, 255, 0);
  color: #fff;
  box-shadow: none;
}
.toolbar-chatBtn .btn-chat-live_transparent:hover {
  box-shadow: none;
  background-color: inherit;
}

.toolbar-chatBtn .btn-chat-live_live {
  padding-right: 22px;
}

span.live-icon-container::before {
  content: '';
  display: block;
  position: absolute;
  top: 0px;
  left: 0;
  width: 150%;
  height: 150%;
  background-image: url('../../../../assets/img/chatbtnicon.png') !important;
  /* background-image: url('path/to/image.jpg'); */
  background-size: cover; /* or 'contain' to fit within the element */
  /* other styling properties */
}

span.live-icon {
  display: inline-block;
  position: relative;
  top: calc(50% - 5px);
  /* background-color: rgba(14, 76, 209, 1); */
  /* background-color: red; */
  background-color: #fff;
  width: 10px;
  height: 10px;
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
}
span.live-icon:before {
  content: '';
  display: block;
  position: absolute;
  /* background-color: rgba(14, 76, 209, 1); */
  /* background-color: rgba(255, 0, 0, 0.6); */
  background-color: #fff;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  -webkit-animation: live 2s ease-in-out infinite;
  animation: live 2s ease-in-out infinite;
  z-index: -1;
}

@-webkit-keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}

@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}
small {
  position: absolute;
  font-size: 0.9em;
  color: rgba(255, 255, 255, 0.5);
  bottom: 2rem;
}
small b {
  font-weight: bold;
  color: rgba(255, 255, 255, 0.8);
  z-index: -1;
}

@media only screen and (max-width: 500px) {
  .toolbar-chatBtn {
    flex-direction: column;
    padding-left: 50px;
    padding-right: 50px;
  }

  .btn-chat-live {
    width: 250px;
  }

  span.live-icon {
    position: absolute;
    right: 20rem;
  }
}
@media only screen and (max-width: 350px) {
  .btn-chat-live {
    width: 100%;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}

span.live-icon-container {
  animation: float 3s ease-in-out infinite;
}

.scroll-feed {
  height: auto !important;
}

.icon-img {
  height: 32px !important;
}

.d-flex {
  display: flex !important;
}

.h-v-center {
}
