.box-shadow {
  /* -webkit-box-shadow: 0 1px 1px rgba(72, 78, 85, 0.6);
  box-shadow: 0 1px 1px rgba(72, 78, 85, 0.6); */
  /* box-shadow: 0 20px 40px rgba(53, 53, 54, 0.6); */
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -ms-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
}

.box-shadow:hover {
  /* -webkit-box-shadow: 0 20px 40px rgba(72, 78, 85, 0.6); */
  /* box-shadow: 0 20px 40px rgba(72, 78, 85, 0.6); */
  /* box-shadow: 0 5px 10px rgba(53, 53, 54, 0.6); */
  /* -webkit-transform: translateY(-15px);
  -moz-transform: translateY(-15px);
  -ms-transform: translateY(-15px);
  -o-transform: translateY(-15px); */
  /* transform: translateY(-15px); */
  transform: scale(1.05);
}

/* .main-panel > .content {
  padding: 0 0 0 0 !important;
} */

.bg-dark {
  background-color: #171717 !important;
  margin-bottom: 0 !important;
  /* padding: 1rem 1rem 0 0 !important; */
  height: 40px;
}
.main-panel .content {
  transition: ease-out all 0.3s;
}

.btn-simple {
  border-color: gray !important;
  /* color: #f18f3c !important; */
}

.btn-simple {
  color: #fff !important;
}

@media only screen and (min-width: 1224px) {
  .sidebar:hover + .main-panel .content {
    padding-left: 20vw !important;
    transition: ease-out all 0.3s;
  }
}

.btn.btn-simple.active {
  background-color: gray !important;
  background-image: none !important;
  border: none !important;
}
.btn.btn-simple.active:hover {
  background-color: gray !important;
  background-image: none !important;
}

.chart-area {
  height: 100% !important;
}

/* canvas {
  height: 300px !important;
} */
.card-chart {
  height: 570px !important;
}
canvas {
  /* height: 500px !important; */
}

.dashboard-row > div {
  height: 300px !important;
}

.assetCard {
  padding: 0 !important;
  margin: 0 !important;
  width: 100% !important;
}

.jtk-overlay {
  /* background-color: #60bfbf56 !important; */
  background-color: transparent !important;
  /* border: 0.2rem dashed #777; */
  border-radius: 35% !important;
  font-size: 0.7rem !important;
}
/* .card-chart > .card {
  height: 500px !important;
} */

/* @media only screen and (max-width: 1000px) {
  .form-control {
    color: red;
    display: block !important;
  }
  .percent {
    color: red;
    display: block !important;
  }
  .btn-group-toggle {
    color: red;
    display: block !important;
  }
} */

/* .card-title > * {
  display: block;
} */
